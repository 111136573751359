@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noor";
  src: local("Noor"), url("../fonts/noor/Noor-Light.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Noor";
  src: local("Noor"), url("../fonts/noor/Noor-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  font-family: "Noor", "sans-serif" !important;
}

html,
body {
  height: 100%;
}

.custom-bg {
  background-image: url("../images/Image2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-bg-2 {
  background-image: url("../images/Image2.png");
  background-size: contain;
}

.svg-container svg {
  transform: translate3d(0px, -40px, 0px) !important;
}

.terms ul {
  list-style: decimal;
}

.react-player-coustom-style > video {
  object-fit: cover !important;
  height: 100%;
  border-radius: 40px;
  padding: 0.25rem;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
